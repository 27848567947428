import { css } from '@emotion/react';
import styled from '@emotion/styled';
import svgToDataUrl from '../../helpers/svg-to-data-url';

const checkboxSvg = ({ theme }, quote) =>
  svgToDataUrl(
    `
    <svg fill='${theme.colors.emerald}' width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <path d="M10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10 0C15.523 0 20 4.477 20 10C20 15.523 15.523 20 10 20ZM9.003 14L16.073 6.929L14.659 5.515L9.003 11.172L6.174 8.343L4.76 9.757L9.003 14Z"/>
    </svg>`,
    quote
  );

const infoboxSvg = ({ theme }, quote) =>
  svgToDataUrl(
    `
      <svg fill='${theme.colors.emerald}' width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
          <path d="M10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10 0C15.523 0 20 4.477 20 10C20 15.523 15.523 20 10 20ZM10 18C12.1217 18 14.1566 17.1571 15.6569 15.6569C17.1571 14.1566 18 12.1217 18 10C18 7.87827 17.1571 5.84344 15.6569 4.34315C14.1566 2.84285 12.1217 2 10 2C7.87827 2 5.84344 2.84285 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10C2 12.1217 2.84285 14.1566 4.34315 15.6569C5.84344 17.1571 7.87827 18 10 18ZM9 5H11V7H9V5ZM9 9H11V15H9V9Z"/>
      </svg>`,
    quote
  );

export default styled.div`
  ul,
  ol {
    --icon-indent: 45px;
    &:first-of-type {
      margin-block-start: 0;
    }
    &:last-child {
      margin-block-end: 0;
    }
    padding-inline-start: var(--icon-indent);
  }
  ul.check,
  ul.info,
  ol.numbers {
    list-style-position: outside;
    li {
      --margin-bottom: 0;
      &:not(:last-child) {
        --margin-bottom: 10px;
      }
      list-style: none;
      position: relative;
      left: calc(0px - var(--icon-indent));
      margin-left: var(--icon-indent);
      margin-bottom: var(--margin-bottom);
      &::before {
        content: '';
        position: absolute;
        left: calc(3px - var(--icon-indent));
        top: 0.2em;
        display: block;
        height: 1.34em;
        width: 1.34em;
        min-height: 1.34em;
        min-width: 1.34em;
        background-size: contain;
        background-repeat: no-repeat;
      }
    }
  }
  ul.check {
    li {
      &::before {
        background-image: url(${({ theme }) => checkboxSvg({ theme }, true)});
      }
    }
  }
  ul.info {
    li {
      &::before {
        background-image: url(${({ theme }) => infoboxSvg({ theme }, true)});
      }
    }
  }
  ol.numbers {
    li {
      &::before {
        content: attr(data-ix);
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: ${({ theme }) => theme.colors.bahamaBlue};
        display: flex;
        align-items: center;
        justify-content: center;
        color: ${({ theme }) => theme.colors.white};
        ${({ theme }) => theme.typography.septemberSmall};
      }
    }
  }
  ol.upper-roman {
    list-style-type: upper-roman;
  }
  ol.upper-alpha {
    list-style-type: upper-alpha;
  }
  ol.lower-alpha {
    list-style-type: lower-alpha;
  }
  ol.lower-greek {
    list-style-type: lower-greek;
  }
  a {
    &.blue {
      color: ${({ theme }) => theme.colors.pacificBlue};
      &:hover {
        color: ${({ theme }) => theme.colors.bahamaBlue};
      }
      &:active {
        color: ${({ theme }) => theme.colors.pacificBlue};
      }
    }
    &.bold {
      font-weight: ${({ theme }) => theme.weights.bold};
    }
    &.semibold {
      font-weight: ${({ theme }) => theme.weights.semibold};
    }
  }
  .quote {
    display: block;
    text-align: center;
    background-color: ${({ theme }) => theme.colors.aliceBlue};
    padding: 30px 15px;
  }
  pre {
  }
  img {
    width: 100%;
    height: auto;
    max-width: var(--source-width);
    max-height: var(--source-height);
  }
  p {
    margin: 27px 0;
  }
  & > *:first-of-type {
    margin-top: 0;
  }
  & > *:last-child {
    margin-bottom: 0;
  }
  table {
    width: 100%;
    border-collapse: collapse;
    border-radius: 4px;
    th,
    td {
      border: 1px solid ${({ theme }) => theme.colors.quartz};
      padding: 10px;
      text-align: left;
    }
    th {
      border-color: ${({ theme }) => theme.colors.quartz};
      background-color: ${({ theme }) => theme.colors.aliceBlue};
    }
  }
  ${({ singleLine = false }) =>
    singleLine &&
    css`
      p,
      div {
        display: inline;
      }
    `}
`;
