import React, { useMemo } from 'react';
import StyledRichText from './RichText.style';
import Heading from '../../components/global/Heading';
import Typography from '../../components/global/Typography';
import Link from '../../components/global/Link';
import Picture from './Picture';

const defaultParseElement = (element) => {
  switch (element.tagName) {
    case 'H1':
      return [Heading, { variant: 'heading1', tag: 'h1' }];
    case 'H2':
      return [Heading, { variant: 'heading2', tag: 'h2' }];
    case 'H3':
      return [Heading, { variant: 'heading3', tag: 'h3' }];
    case 'H4':
      return [Heading, { variant: 'heading4', tag: 'h4' }];
    case 'H5':
      return [Heading, { variant: 'heading5', tag: 'h5', color: 'trout' }];
    case 'H6':
      return [Heading, { variant: 'heading6', tag: 'h6', color: 'trout' }];
    case 'BLOCKQUOTE':
      return [Typography, { className: 'quote', variant: 'inherit' }];
    case 'P':
      return [Typography, { tag: 'p', variant: 'inherit' }];
    case 'DIV':
      return [Typography, { variant: 'inherit' }];
    case 'PRE':
      return [Typography, { tag: 'pre', variant: 'inherit' }];
    case 'STRONG':
      return [(p) => <span {...p} />, { className: 'bold' }];
    case 'EM':
      return [(p) => <span {...p} />, { className: 'italic' }];
    case 'SPAN':
      if (element.style.textDecoration === 'underline')
        return [(p) => <span {...p} />, { className: 'underline' }];
      return [Typography, { tag: 'span', variant: 'inherit' }];
    case 'A':
      return [
        (p) => <Link {...p} />,
        {
          href: element?.getAttribute('href'),
          rel: element?.getAttribute('rel'),
          target: element?.getAttribute('target'),
          onClick: element?.getAttribute('data-scroll-to-module')
            ? ((module) => (e) => {
                e.preventDefault();
                e.stopPropagation();
                window.scrollToModule(module);
              })(element?.getAttribute('data-scroll-to-module'))
            : null,
          className: element?.getAttribute('class'),
          role: element?.getAttribute('role'),
        },
      ];
    case 'UL': {
      const attr = {};
      switch (element.style.listStyleType) {
        case 'circle':
          attr.className = 'check';
          break;
        case 'square':
          attr.className = 'info';
          break;
      }
      return [(p) => <ul {...p} />, attr];
    }
    case 'OL': {
      const attr = {};
      switch (element.style.listStyleType) {
        case 'upper-roman':
          attr.className = 'upper-roman';
          break;
        case 'upper-alpha':
          attr.className = 'upper-alpha';
          break;
        case 'lower-alpha':
          attr.className = 'lower-alpha';
          break;
        case 'lower-greek':
          attr.className = 'lower-greek';
          break;
        default:
          attr.className = 'numbers';
          break;
      }
      return [(p) => <ol {...p} />, attr];
    }
    case 'LI':
      return [Typography, { tag: 'li', variant: 'inherit' }];
    case 'BR':
      return [({ children, ...p }) => <br {...p} />, {}];
    case 'IMG':
      return [
        Picture,
        {
          images: { 1: element?.getAttribute('src') },
          width: element?.getAttribute('width'),
          height: element?.getAttribute('height'),
          alt: element?.getAttribute('alt') || '',
        },
      ];
    case 'CAPTION':
      return [(p) => <caption {...p} />, {}];
    case 'TABLE':
      return [(p) => <table {...p} />, {}];
    case 'THEAD':
      return [(p) => <thead {...p} />, {}];
    case 'TBODY':
      return [(p) => <tbody {...p} />, {}];
    case 'TR':
      return [(p) => <tr {...p} />, {}];
    case 'TD':
      return [
        (p) => <td {...p} />,
        {
          colspan: element.getAttribute('colspan'),
          rowspan: element.getAttribute('rowspan'),
        },
      ];
    case 'TH':
      return [
        (p) => <th {...p} />,
        {
          colspan: element.getAttribute('colspan'),
          rowspan: element.getAttribute('rowspan'),
        },
      ];
    default:
      return [React.Fragment, {}];
  }
};

/**
 *
 * @param {HTMLElement} element
 * @returns
 */
const parseElement = (element, settings = {}) => {
  if (settings.remap) {
    if (typeof settings.remap === 'function')
      return settings.remap(element, settings, defaultParseElement);
    if (settings.remap[element.tagName]) {
      if (typeof settings.remap[element.tagName] === 'function')
        return settings.remap[element.tagName](
          element,
          settings,
          defaultParseElement
        );
      return settings.remap[element.tagName];
    }
  }
  return defaultParseElement(element);
};

const parseContainer = (container, p, settings = {}, Element = undefined) => {
  let baseP = {};
  if (Element === undefined)
    [Element, baseP] = parseElement(container, settings);
  return (
    <Element
      {...baseP}
      {...p}
      {...(baseP.tag === 'li' && p ? { 'data-ix': (p?.key + 1) / 2 } : {})}
    >
      {Array.from(container.childNodes).map((n, ix) => {
        switch (n.nodeType) {
          case Node.ELEMENT_NODE: {
            return parseContainer(n, { key: ix }, settings);
          }
          case Node.TEXT_NODE:
            return <React.Fragment key={ix}>{n.textContent}</React.Fragment>;
        }
      })}
    </Element>
  );
};

const RichText = ({ content, parserSettings, ...p }, ref) => {
  const children = useMemo(() => {
    const container = document.createElement('div');
    container.innerHTML = content;
    return parseContainer(container, undefined, parserSettings, React.Fragment);
  }, [content]);
  return (
    <StyledRichText ref={ref} {...p}>
      {children}
    </StyledRichText>
  );
};
export default React.forwardRef(RichText);
